import BgImageStripComponent from '@wix/thunderbolt-elements/src/components/BgImageStrip/viewer/BgImageStrip';


const BgImageStrip = {
  component: BgImageStripComponent
};


export const components = {
  ['BgImageStrip']: BgImageStrip
};

